import React, { useEffect } from 'react';
import { Grid, Spinner } from '@myci/ui-components';

const PaymentFail = () => {
	useEffect(() => {
		window.ReactNativeWebView.postMessage('fail');
	}, []);

	return (
		<Grid
			className="content-fullscreen"
			flexDirection={{ xs: 'column', md: 'row' }}
			alignItems="center"
		>
			<Spinner />
		</Grid>
	);
};

export default PaymentFail;
